export const config = {
    build_timestamp: "2025-03-25T17:02:11.632Z",
    geoKey: "AIzaSyBeeIWUhRhc2ZW9oKxUugzu8y9JQgFVcvA",
    zoomSdkKey: "Egz3OjF7ITKvLvhHxRhxLNa5us9uJQ24oJX7",
    mockServices: false,
    env: "global",
    serverUrl: "https://global.eonlineworkshop.com/dhWebsite",
    imgUrl: "https://global.eonlineworkshop.com//",
    razorAppKey: "rzp_live_0FiGdfujDNHPj1",
};
